import {PATH} from 'constants/paths';
import {useFetch} from 'hooks/useFetch';
import {useCallback} from 'react';

export const usePostReverseProxyRule = () => {
  const {isLoading, sendRequest} = useFetch();

  const createRule = useCallback(
    async (token, body, onSuccess = undefined, onError = undefined) => {
      await sendRequest(
        'POST',
        PATH.reverseProxyRule,
        {
          authorization: token,
        },
        body,
        onSuccess,
        onError
      );
    },
    [sendRequest]
  );

  return {
    isCreatingRule: isLoading,
    createRule,
  };
};
