import {createSlice} from '@reduxjs/toolkit';

export const createNotification = (message, severity = 'success', autoHideDuration = 3000) => {
  return {
    open: true,
    autoHideDuration: autoHideDuration,
    message: message,
    severity: severity,
  };
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    active: {
      open: false,
      autoHideDuration: 4000,
      message: 'Olá',
      severity: 'error',
    },
  },
  reducers: {
    setActiveNotification: (state, action) => {
      state.active = action.payload;
    },
  },
});

export const {setActiveNotification} = notificationSlice.actions;

export default notificationSlice.reducer;
