import {Box, Typography} from '@mui/material';
import Lottie from 'lottie-react';
import * as animationData from './empty.json';

export const EmptyState = ({message, action}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        maxHeight: '100px',
        mt: 10,
      }}
    >
      <Lottie animationData={animationData} style={{height: 100}} />
      <Typography>{message}</Typography>
      {action}
    </Box>
  );
};
