import {Accordion, AccordionDetails, AccordionSummary, Box, Chip, Typography} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';
import {FormReverseProxy} from 'pages/ReverseProxyPage/FormReverseProxy';
import {toHuman} from 'formatters/bytes';

export const AccordionItem = ({open, onChangeStatus, host}) => {
  const handleChangeStatus = () => onChangeStatus(host);

  return (
    <Accordion
      expanded={open}
      onChange={handleChangeStatus}
      TransitionProps={{unmountOnExit: true}}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography sx={{width: '33%'}}>{host.host}</Typography>
        <Box sx={{width: '300px', display: 'flex', justifyContent: 'space-between'}}>
          <Chip label={`Hits: ${host.hitsCount}`} size={'small'} />
          <Chip label={`Tráfego: ${toHuman(host.bytesOut)}`} size={'small'} />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <FormReverseProxy host={host} open={open} />
      </AccordionDetails>
    </Accordion>
  );
};
