import {Grid, TextField} from '@mui/material';

export const FormInfra = ({value, onChange, isEditing}) => {
  const handleChangeValue = ({target}) =>
    onChange({
      ...value,
      [target.name]: target.value,
    });

  return (
    <Grid container>
      <Grid container spacing={2} margin={2}>
        <Grid item xs={12} md={3}>
          <TextField
            disabled={isEditing}
            fullWidth
            label={'Hosts'}
            value={value.hosts}
            name={'hosts'}
            onChange={handleChangeValue}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={'IPs'}
            value={value.ips}
            name="ips"
            onChange={handleChangeValue}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
