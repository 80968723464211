import {Template} from 'components/Template';
import {Box, Button, Grid, Typography} from '@mui/material';
import {MoreOptions} from 'components/MoreOptions/MoreOptions';
import {CenteredLoading} from 'components/CenteredLoading';
import {EmptyState} from 'components/EmptyState';
import {useGetAdminUsers} from 'hooks/adminusers/useGetAdminUsers';
import {useNavigate} from 'react-router';
import {useSelector} from 'react-redux';
import {ListAdminUsers} from 'pages/AdminUsersPage/ListAdminUsers';
import {useEffect} from 'react';

export const AdminUsersPage = () => {
  const token = useSelector(state => state.authentication.token);
  const {adminUsers, isLoadingAdminUsers, getAdminUsers} = useGetAdminUsers(token);
  const navigate = useNavigate();

  useEffect(() => {
    getAdminUsers(token).then();
  }, [getAdminUsers, token]);

  const renderActionForEmptyState = () => {
    return (
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{mt: 1}}>
          <Button variant={'contained'} onClick={() => navigate('/adminusers/manage')}>
            Criar usuário administrador
          </Button>
        </Box>
      </Box>
    );
  };

  const renderUsers = () => {
    if (isLoadingAdminUsers) return <CenteredLoading />;
    if (!adminUsers?.length)
      return (
        <EmptyState
          message={'Ainda não existem administradores cadastrados'}
          action={renderActionForEmptyState()}
        />
      );
    return <ListAdminUsers adminUsers={adminUsers} />;
  };

  const options = [{text: 'Criar usuário', onClick: () => navigate('/adminusers/manage')}];

  return (
    <Template>
      <Grid container>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid xs={6} item>
            <Typography variant={'h4'}>Usuários administradores</Typography>
          </Grid>
          <Grid item>
            <MoreOptions options={options} />
          </Grid>
        </Grid>
        <Grid container mt={4}>
          <Grid item xs={12}>
            {renderUsers()}
          </Grid>
        </Grid>
      </Grid>
    </Template>
  );
};
