import {useCallback, useState} from 'react';
import {PATH} from 'constants/paths';

export const useListFiles = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  const sendRequest = useCallback(async (token, document, folder) => {
    try {
      setLoading(true);
      const url = `${PATH.files}?document=${document}&folder=${folder}`;
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          authorization: token,
        },
      });
      if (!resp.ok) {
        setData([]);
        setError('invalid response');
        return;
      }
      const json = await resp.json();
      setData(json);
    } catch (e) {
      setData([]);
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    files: data,
    isLoadingFiles: isLoading,
    errorOnLoadFiles: error,
    listFiles: sendRequest,
  };
};
