import {PATH} from 'constants/paths';
import {useFetch} from 'hooks/useFetch';
import {useCallback} from 'react';

export const useGetHosts = () => {
  const {data, isLoading, sendRequest} = useFetch();

  const request = useCallback(
    async (token, onSuccess = undefined, onError = undefined) => {
      await sendRequest(
        'GET',
        PATH.reverseProxyHosts,
        {
          authorization: token,
        },
        null,
        onSuccess,
        onError
      );
    },
    [sendRequest]
  );

  return {
    hosts: data,
    isLoadingRulesHosts: isLoading,
    getHosts: request,
  };
};
