import {useCallback, useState} from 'react';
import {PATH} from 'constants/paths';

export const useGetAccounts = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  const sendRequest = useCallback(async token => {
    try {
      setLoading(true);
      const resp = await fetch(PATH.accounts, {
        method: 'GET',
        headers: {
          authorization: token,
        },
      });
      if (!resp.ok) {
        setData([]);
        setError('invalid response');
        return;
      }
      const json = await resp.json();
      setData(json);
    } catch (e) {
      setData([]);
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    accounts: data,
    isLoadingAccounts: isLoading,
    errorOnLoadAccounts: error,
    loadAccounts: sendRequest,
  };
};
