import {Divider, Menu, MenuItem} from '@mui/material';
import {useDispatch} from 'react-redux';
import {logout} from 'store/authentication';

export const UserMenu = ({anchorEl, open, onClose}) => {
  const dispatch = useDispatch();

  const handleClose = () => onClose();
  const handleLogout = () => {
    dispatch(logout());
    onClose();
  };

  const options = [{text: 'Sair', onClick: handleLogout}];

  const renderMenuItem = opt => {
    if (opt.divider) {
      return <Divider key={Math.random().toString()} />;
    }

    return (
      <MenuItem disabled={opt.disabled} onClick={event => opt.onClick(event)} key={opt.text}>
        {opt.text}
      </MenuItem>
    );
  };

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      {options?.map(renderMenuItem)}
    </Menu>
  );
};
