import {Template} from 'components/Template';
import {Box, Button, CircularProgress, Grid, Tab, Tabs} from '@mui/material';
import {useEffect, useState} from 'react';
import {FormBasicInfos} from './FormBasicInfos';
import {useLocation, useNavigate} from 'react-router';
import {initialAccount} from 'constants/initialState';
import {FormAddress} from './FormAddress';
import {useEditAccount} from 'hooks/accounts/useEditAccount';
import {useCreateAccount} from 'hooks/accounts/useCreateAccount';
import {FormInfra} from 'pages/AccountPage/ManageAccount/FormInfra';
import {useSelector} from 'react-redux';
import {FormModules} from 'pages/AccountPage/ManageAccount/FormModules';
import {useGetModulesForAccount} from 'hooks/accounts/useGetModulesForAccount';
import {usePutModulesForAccount} from 'hooks/accounts/usePutModulesForAccount';

export const ManageAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [account, setAccount] = useState(initialAccount);
  const [modules, setModules] = useState([]);
  const token = useSelector(state => state.authentication.token);

  const {editAccount, isEditingAccount} = useEditAccount();
  const {createAccount, isCreatingAccount} = useCreateAccount();
  const {isLoadingModulesForAccounts, getModulesForAccount, modulesForAccount} =
    useGetModulesForAccount();
  const {putModulesForAccount, isPuttingModulesForAccount} = usePutModulesForAccount();

  useEffect(() => {
    if (account.id) {
      getModulesForAccount(token, account.id).then();
    }
  }, [getModulesForAccount, account.id, token]);

  useEffect(() => {
    if (modulesForAccount) {
      const m = modulesForAccount
        .filter(m => m.enabled)
        .map(m => ({module: m.module, items: m.items}));
      setModules(m);
    } else {
      setModules([]);
    }
  }, [modulesForAccount]);

  useEffect(() => {
    if (location.state)
      setAccount({
        ...initialAccount,
        ...location.state,
        document: location.state?.id,
        phones: location.state.phones?.join(',') || '',
        emails: location.state.emails?.join(',') || '',
        ips: location.state.ips?.join(',') || '',
        hosts: location.state.hosts?.join(',') || '',
      });
  }, [location.state]);

  const handleChange = (event, newValue) => setSelectedTab(newValue);
  const handleChangeForm = value => setAccount(prev => ({...prev, ...value}));
  const handleChangeModules = module => {
    const isSelected = modules.some(m => m.module === module);
    if (isSelected) {
      setModules(m => m.filter(m => m.module !== module));
    } else {
      setModules(m => [...m, {module}]);
    }
  };

  const renderContent = () => {
    if (!account) return null;

    switch (selectedTab) {
      case 0:
        return (
          <FormBasicInfos
            value={account}
            onChange={handleChangeForm}
            isEditing={!!location.state}
          />
        );
      case 1:
        return <FormAddress value={account} onChange={handleChangeForm} />;
      case 2:
        return <FormInfra value={account} onChange={handleChangeForm} />;
      case 3:
        return (
          <FormModules
            value={modules}
            onChange={handleChangeModules}
            isLoadingModulesForAccounts={isLoadingModulesForAccounts}
          />
        );
      default:
        return null;
    }
  };

  const handleOnSuccess = async () => {
    await putModulesForAccount(token, account.id, modules, () => navigate('/accounts'));
  };

  const handleCancel = () => navigate(-1);
  const handleSave = async () => {
    const newAccount = {...account};
    newAccount.phones = !Array.isArray(account.phones)
      ? account.phones?.split(',')
      : account.phones;
    newAccount.emails = !Array.isArray(account.emails)
      ? account.emails?.split(',')
      : account.emails;
    newAccount.ips = !Array.isArray(account.ips) ? account.ips?.split(',') : account.ips;
    newAccount.hosts = !Array.isArray(account.hosts) ? account.hosts?.split(',') : account.hosts;

    if (location.state) {
      await editAccount(token, location.state.id, newAccount, handleOnSuccess);
    } else {
      await createAccount(token, newAccount, handleOnSuccess);
    }
  };

  const canPostForm = () => {
    return !(!account.document?.trim() || !account.partner.trim() || !account.name.trim());
  };

  return (
    <Template>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={selectedTab} onChange={handleChange}>
          <Tab label="Informações básicas" />
          <Tab label="Endereço" />
          <Tab label="Infraestrutura" />
          <Tab label={'Módulos'} />
        </Tabs>
      </Box>
      {renderContent()}
      <Grid container justifyContent={'flex-end'} sx={{mt: 2}}>
        <Grid item>
          <Button sx={{mr: 2}} onClick={handleCancel}>
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button variant={'contained'} onClick={handleSave} disabled={!canPostForm()}>
            {isCreatingAccount || isEditingAccount || isPuttingModulesForAccount ? (
              <CircularProgress size={'1.2rem'} color={'inherit'} />
            ) : (
              'Salvar'
            )}
          </Button>
        </Grid>
      </Grid>
    </Template>
  );
};
