import {useCallback, useState} from 'react';
import {PATH} from 'constants/paths';

export const useCreateAccount = () => {
  const [isLoading, setLoading] = useState(false);

  const sendRequest = useCallback(
    async (token, body, onSuccess = () => undefined, onError = () => undefined) => {
      try {
        setLoading(true);
        const resp = await fetch(PATH.accounts, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            authorization: token,
          },
        });
        if (!resp.ok) {
          onError();
          return;
        }
        const json = await resp.json();
        onSuccess(json);
      } catch (e) {
        onError();
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    isCreatingAccount: isLoading,
    createAccount: sendRequest,
  };
};
