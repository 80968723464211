import {useCallback, useState} from 'react';
import {PATH} from 'constants/paths';

export const useGetAccountsByPartners = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  const sendRequest = useCallback(async (token, allPartners = false, partners = []) => {
    const partnersParam = partners.map(partner => `partner=${partner}`).join('&');

    try {
      setLoading(true);
      const resp = await fetch(
        `${PATH.accountsByPartners}?allPartners=${allPartners}&${partnersParam}`,
        {
          method: 'GET',
          headers: {
            authorization: token,
          },
        }
      );
      if (!resp.ok) {
        setData([]);
        setError('invalid response');
        return;
      }
      const json = await resp.json();
      setData(json);
    } catch (e) {
      setData([]);
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    accounts: data,
    isLoadingAccountsByPartner: isLoading,
    errorOnLoadAccountsByPartners: error,
    loadAccountsByPartners: sendRequest,
  };
};
