import {useState} from 'react';

export const useCEP = () => {
  const [data, setData] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();

  const getCEP = async cep => {
    let formattedCEP = cep.replace(/\D/g, '');
    if (formattedCEP.length !== 8) return;

    try {
      setLoading(true);
      const resp = await fetch(`https://viacep.com.br/ws/${formattedCEP}/json/`);
      if (!resp.ok) {
        setError('invalid request');
        setData(undefined);
        return;
      }

      const d = await resp.json();
      setData(d);
      setError(undefined);
    } catch (e) {
      setError(e);
      setData(undefined);
    } finally {
      setLoading(false);
    }
  };

  return {
    address: data,
    isLoadingCEP: isLoading,
    isCEPError: error,
    getCEP,
  };
};
