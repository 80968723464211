import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {useGetReverseProxyRules} from 'hooks/reverseproxy/useGetReverseProxyRules';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {CenterDiv} from 'components/CenterDiv/CenterDiv';
import {usePostReverseProxyRule} from 'hooks/reverseproxy/usePostReverseProxyRule';
import {v4 as uuidv4} from 'uuid';
import {ArrowDownward, ArrowUpward, Delete} from '@mui/icons-material';
import {useDeleteReverseProxyRule} from 'hooks/reverseproxy/useDeleteReverseProxyRule';
import {toHuman} from 'formatters/bytes';

const isEqualObj = (obj1, obj2) => {
  let isEqual = true;
  Object.keys(obj1).forEach(key => {
    if (obj1[key] !== obj2[key]) {
      isEqual = false;
    }
  });
  return isEqual;
};

const defaultRule = {route: '', action: '', destination: '', forward404ToIndex: false};

export const FormReverseProxy = ({host, open}) => {
  const {getRules, isLoadingRules, rules} = useGetReverseProxyRules();
  const {createRule, isCreatingRule} = usePostReverseProxyRule();
  const {deleteRule, isDeletingRule} = useDeleteReverseProxyRule();

  const [editedRules, setEditedRules] = useState([]);
  const token = useSelector(state => state.authentication.token);

  useEffect(() => {
    if (open) {
      getRules(token, host.host).then();
    }
  }, [host, getRules, token, open]);

  useEffect(() => {
    setEditedRules(rules || []);
  }, [rules]);

  const handleChangeValue = (index, {target}) => {
    setEditedRules(prev => {
      const tmpRule = [...prev];
      tmpRule[index] = {...tmpRule[index], [target.name]: target.value};
      return tmpRule;
    });
  };

  const handleChangeForward404 = index => {
    setEditedRules(prev => {
      const tmpRule = [...prev];
      tmpRule[index] = {...tmpRule[index], forward404ToIndex: !tmpRule[index]['forward404ToIndex']};
      return tmpRule;
    });
  };

  const isRuleValid = rule => {
    return !(!rule.route?.trim() || !rule.action?.trim() || !rule.destination?.trim());
  };

  const handleAddRule = () =>
    setEditedRules([
      ...editedRules,
      {
        ...defaultRule,
        new: true,
        id: uuidv4(),
      },
    ]);

  const handleSave = async () => {
    for (let index in editedRules) {
      const rule = editedRules[index];
      if (rule.markToDelete) {
        await deleteRule(token, rule.id);
      } else {
        await createRule(token, {...rule, order: +index, host: host.host});
      }
    }

    await getRules(token, host.host);
  };

  const handleRemove = id => {
    setEditedRules(prev => {
      const index = prev.findIndex(rule => rule.id === id);
      const tmpEditedRules = [...prev];
      tmpEditedRules[index]['markToDelete'] = true;
      return tmpEditedRules;
    });
  };

  const handleMoveUp = index => {
    if (index === 0) return;

    setEditedRules(prev => {
      const tmpEditedRules = [...prev];
      const removeItem = tmpEditedRules.splice(index, 1);
      tmpEditedRules.splice(index - 1, 0, removeItem[0]);
      return tmpEditedRules;
    });
  };

  const handleMoveDown = index => {
    if (index + 1 === rules.length) return;

    setEditedRules(prev => {
      const tmpEditedRules = [...prev];
      const removeItem = tmpEditedRules.splice(index, 1);
      tmpEditedRules.splice(index + 1, 0, removeItem[0]);
      return tmpEditedRules;
    });
  };

  const canPostForm = () => {
    if (isLoadingRules || isCreatingRule || isDeletingRule) return false;
    if (editedRules?.some(rule => !isRuleValid(rule))) return false;
    if (editedRules?.length !== rules?.length) return true;
    if (editedRules?.some(rule => rule.markToDelete)) return true;
    if (editedRules?.some(rule => rule.new)) return true;
    return editedRules.some((rule, index) => !isEqualObj(rule, rules[index]));
  };

  if (!open) return null;

  const renderRules = () => {
    if (isLoadingRules) return <CenterDiv>Carregando..</CenterDiv>;

    if (!editedRules?.length)
      return <CenterDiv>Nenhuma regra encontrada para esse hosts</CenterDiv>;

    if (editedRules?.every(rule => rule.markToDelete))
      return <CenterDiv>Todas as regras existentes serão excluidas</CenterDiv>;

    return editedRules
      ?.filter(rule => !rule.markToDelete)
      ?.map((rule, index) => (
        <Grid container item key={`${rule.id}`}>
          <Grid container spacing={2} margin={2}>
            <Grid item xs>
              <TextField
                fullWidth
                label={'Rota'}
                value={rule.route}
                name="route"
                onChange={handleChangeValue.bind(this, index)}
              />
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <InputLabel>Ação</InputLabel>
                <Select
                  fullWidth
                  label={'Ação'}
                  value={rule.action}
                  name="action"
                  onChange={handleChangeValue.bind(this, index)}
                >
                  <MenuItem value={'localFile'}>Arquivo local</MenuItem>
                  <MenuItem value={'forwardTo'}>Encaminhar para</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                label={'Destino'}
                value={rule.destination}
                name="destination"
                onChange={handleChangeValue.bind(this, index)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} margin={2}>
            <Grid item xs>
              <FormControlLabel
                control={
                  <Checkbox
                    name="forward404ToIndex"
                    onChange={handleChangeForward404.bind(this, index)}
                    checked={rule.forward404ToIndex}
                  />
                }
                label={'Encaminhar 404 para index'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mx={2} justifyContent={'space-between'}>
            <Box
              sx={{
                width: '200px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Chip label={`Hits: ${rule.hits}`} size={'small'} />
              <Chip label={`Tráfego: ${toHuman(rule.bytes)}`} size={'small'} />
            </Box>
            <Grid container xs={12} md={8} justifyContent={'flex-end'} alignItems={'center'}>
              <Grid item>
                {index ? (
                  <Button onClick={handleMoveUp.bind(null, index)} startIcon={<ArrowUpward />}>
                    Mover para cima
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                {index + 1 !== editedRules?.length ? (
                  <Button onClick={handleMoveDown.bind(null, index)} startIcon={<ArrowDownward />}>
                    Mover para baixo
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Button
                  onClick={handleRemove.bind(null, rule.id)}
                  color={'error'}
                  startIcon={<Delete />}
                >
                  Remover
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container margin={2}>
            <Grid item xs>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
      ));
  };

  return (
    <Grid container>
      <Grid container justifyContent={'center'} spacing={2}>
        {renderRules()}
      </Grid>
      <Grid container justifyContent={'flex-end'} spacing={2} m={2}>
        <Grid item>
          <Button onClick={handleAddRule}>Adicionar nova regra</Button>
        </Grid>
        <Grid item>
          <Button variant={'contained'} onClick={handleSave} disabled={!canPostForm()}>
            {isLoadingRules || isCreatingRule || isDeletingRule ? (
              <CircularProgress size={'1.2rem'} />
            ) : (
              'Salvar'
            )}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
