import {Checkbox, FormControlLabel, Grid, TextField} from '@mui/material';

export const BasicInfosForm = ({value, onChange}) => {
  const handleChangeValue = ({target}) =>
    onChange({
      ...value,
      [target.name]: target.value,
    });

  return (
    <Grid container>
      <Grid container spacing={2} margin={2}>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            label={'Email'}
            value={value.email}
            name={'email'}
            onChange={handleChangeValue}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} margin={2}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={'Nome'}
            value={value.name}
            name="name"
            onChange={handleChangeValue}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={'Sobrenome'}
            value={value.lastName}
            name={'lastName'}
            onChange={handleChangeValue}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} margin={2}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={'Senha'}
            value={value.password}
            name="password"
            type={'password'}
            onChange={handleChangeValue}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={'Confirmar senha'}
            value={value.confirmPassword}
            name={'confirmPassword'}
            type={'password'}
            onChange={handleChangeValue}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} margin={2}>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={<Checkbox />}
            label={'Forçar o reset de senha no próximo login'}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
