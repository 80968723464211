import {useCallback, useState} from 'react';
import {PATH} from 'constants/paths';

export const useGetUsers = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  const sendRequest = useCallback(async (token, document) => {
    try {
      setLoading(true);
      const url = `${PATH.users}?document=${document}`;
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          authorization: token,
        },
      });
      if (!resp.ok) {
        setData([]);
        setError('invalid response');
        return;
      }
      const json = await resp.json();
      setData(json);
      setError(undefined);
    } catch (e) {
      setData([]);
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    users: data,
    isLoadingUsers: isLoading,
    errorOnGetUsers: error,
    getUsers: sendRequest,
  };
};
