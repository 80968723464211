import {Template} from 'components/Template';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useEffect, useState} from 'react';
import {CenteredLoading} from 'components/CenteredLoading';
import {EmptyState} from 'components/EmptyState';
import {Button, Grid, Typography} from '@mui/material';
import {Add} from '@mui/icons-material';
import {useGetPartners} from 'hooks/partners/useGetPartners';
import {ListPartners} from 'pages/PartnersPage/ListPartners';
import {ModalYesNo} from 'components/ModalYesNo/ModalYesNo';
import {useDeletePartner} from 'hooks/partners/useDeletePartner';

export const PartnersPage = () => {
  const [deletingPartner, setDeletingPartner] = useState();
  const token = useSelector(state => state.authentication.token);
  const navigate = useNavigate();

  const {partners, isLoadingPartners, loadPartners} = useGetPartners();
  const {deletePartner, isDeletingPartner} = useDeletePartner();

  useEffect(() => {
    loadPartners(token).then();
  }, [loadPartners, token]);

  const renderPartnersList = () => {
    if (isLoadingPartners) return <CenteredLoading />;
    if (!partners?.length) return <EmptyState message={'Ainda não há parceiros cadastrados!'} />;
    return <ListPartners partners={partners} onDelete={setDeletingPartner} />;
  };

  const handleSuccess = () => {
    loadPartners(token).then();
    setDeletingPartner(undefined);
  };
  const handleCancelDelete = () => setDeletingPartner(undefined);
  const handleDeletePartner = async () =>
    await deletePartner(token, deletingPartner?.document, handleSuccess);

  return (
    <Template>
      <ModalYesNo
        onYes={handleDeletePartner}
        yesText={'Sim, excluir'}
        noText={'Não'}
        loading={isDeletingPartner}
        onNo={handleCancelDelete}
        title={'Excluir parceiro'}
        open={deletingPartner}
        text={'Excluir o parceiro, removerá também todas as contas vinculadas. Deseja continuar?'}
      />
      <Grid container spacing={2}>
        <Grid xs={6} item>
          <Typography variant={'h4'}>Parceiros</Typography>
        </Grid>
        <Grid xs={6} item container justifyContent={'flex-end'}>
          <Button startIcon={<Add />} onClick={() => navigate('/partners/manage')}>
            Criar novo
          </Button>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '200px'}}>
          {renderPartnersList()}
        </Grid>
      </Grid>
    </Template>
  );
};
