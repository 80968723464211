import {useCallback, useState} from 'react';
import {PATH} from 'constants/paths';

const sendFile = (token, document, file, body, onProgress) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('content', file);
    formData.append('infos', JSON.stringify(body));

    const request = new XMLHttpRequest();
    request.setRequestHeader('authorization', token);

    request.onerror = error => reject(error);
    request.onload = () => resolve();
    request.upload.onprogress = onProgress;

    request.open('POST', `${PATH.files}/create_file?document=${document}`);
    request.send(formData);
  });
};

export const useUploadFile = () => {
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  const sendRequest = useCallback(
    async (token, document, folder, file, onProgress = () => undefined) => {
      setLoading(true);
      try {
        await sendFile(
          token,
          document,
          file,
          {
            path: folder,
            file: file.name,
          },
          onProgress
        );
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    isUploading: isLoading,
    uploadFile: sendRequest,
    errorUploadingFile: error,
  };
};
