import {Template} from 'components/Template';
import {ListHosts} from 'pages/ReverseProxyPage/ListHosts';
import {Button, Grid, Typography} from '@mui/material';
import {Add} from '@mui/icons-material';
import {useNavigate} from 'react-router';
import {CenteredLoading} from 'components/CenteredLoading';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useGetHosts} from 'hooks/reverseproxy/useGetHosts';

export const ReverseProxyPage = () => {
  const navigate = useNavigate();
  const {hosts, getHosts, isLoadingRulesHosts} = useGetHosts();
  const token = useSelector(state => state.authentication.token);

  useEffect(() => {
    getHosts(token).then();
  }, [getHosts, token]);

  const renderRules = () => {
    if (isLoadingRulesHosts) return <CenteredLoading />;
    return <ListHosts hosts={hosts} />;
  };

  return (
    <Template>
      <Grid container spacing={2}>
        <Grid xs={6} item>
          <Typography variant={'h4'}>Regras do proxy reverso</Typography>
        </Grid>
        <Grid xs={6} item container justifyContent={'flex-end'}>
          <Button startIcon={<Add />} onClick={() => navigate('/reverseproxy/manage')}>
            Criar nova
          </Button>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '200px'}}>
          {renderRules()}
        </Grid>
      </Grid>
    </Template>
  );
};
