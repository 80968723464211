import {MoreVert} from '@mui/icons-material';
import {Divider, IconButton, Menu, MenuItem} from '@mui/material';
import {useState} from 'react';

export const MoreOptions = ({options, data}) => {
  const [anchorEl, setAnchorEl] = useState();
  const [open, setOpen] = useState(false);

  const handleClose = event => {
    setOpen(false);
    event.stopPropagation();
  };

  const handleClick = (callback, event) => {
    event.stopPropagation();
    if (callback) {
      callback(data);
      setOpen(false);
    }
  };

  const renderMenuItem = opt => {
    if (opt.divider) {
      return <Divider key={Math.random().toString()} />;
    }

    return (
      <MenuItem
        disabled={opt.disabled}
        onClick={event => handleClick(opt.onClick, event)}
        key={opt.text}
      >
        {opt.text}
      </MenuItem>
    );
  };

  const handleOpen = event => {
    setOpen(true);
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <>
      <IconButton ref={el => setAnchorEl(el)} color={'primary'} onClick={handleOpen}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options?.map(renderMenuItem)}
      </Menu>
    </>
  );
};
