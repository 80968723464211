import {Box, Typography} from '@mui/material';
import Lottie from 'lottie-react';
import * as animationData from './loading.json';

export const CenteredLoading = ({message}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '300px',
        minHeight: '100px',
      }}
    >
      <Lottie animationData={animationData} style={{height: 100}} />
      <Typography>{message || 'Carregando..'}</Typography>
    </Box>
  );
};
