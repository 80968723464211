export const toK = bytes => bytes / 1024;
export const toM = bytes => bytes / (1024 * 1024);
export const toG = bytes => bytes / (1024 * 1024 * 1024);
export const toT = bytes => bytes / (1024 * 1024 * 1024 * 1024);

const increaseUnit = actual => {
  switch (actual) {
    case 'b':
      return 'Kb';
    case 'Kb':
      return 'Mb';
    case 'Mb':
      return 'Gb';
    case 'Gb':
      return 'Tb';
    case 'Tb':
      return 'Pb';
    default:
      return actual;
  }
};

export const toHuman = bytes => {
  let unit = 'b';
  while (bytes > 1024) {
    bytes = bytes / 1024;
    unit = increaseUnit(unit);
  }
  return `${bytes?.toFixed(1) || 0}${unit}`;
};
