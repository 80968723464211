import {Template} from 'components/Template';
import {Box, Button, Grid, Tab, Tabs} from '@mui/material';
import {useEffect, useState} from 'react';
import {FormBasicInfos} from './FormBasicInfos';
import {useLocation, useNavigate} from 'react-router';
import {initialPartner} from 'constants/initialState';
import {FormAddress} from './FormAddress';
import {useCreatePartner} from 'hooks/partners/useCreatePartner';
import {useSelector} from 'react-redux';
import {useEditPartner} from 'hooks/partners/useEditPartner';

export const ManagePartner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [partner, setPartner] = useState(initialPartner);

  useEffect(() => {
    if (location.state)
      setPartner({
        ...initialPartner,
        ...location.state,
        document: location.state?.document.replace(/\D/g, ''),
        phones: location.state.phones?.join(',') || '',
        emails: location.state.emails?.join(',') || '',
      });
  }, [location.state]);

  const token = useSelector(state => state.authentication.token);

  const {createPartner, isCreatingPartner} = useCreatePartner();
  const {editPartner, isEditingPartner} = useEditPartner();

  const handleChange = (event, newValue) => setSelectedTab(newValue);
  const handleChangeForm = value => setPartner(prev => ({...prev, ...value}));

  const renderContent = () => {
    if (!partner) return null;

    switch (selectedTab) {
      case 0:
        return <FormBasicInfos value={partner} onChange={handleChangeForm} />;
      case 1:
        return <FormAddress value={partner} onChange={handleChangeForm} />;
      default:
        return null;
    }
  };

  const handleSuccess = () => {
    navigate('/partners');
  };

  const handleCancel = () => navigate('/partners');
  const handleSave = async () => {
    const newPartner = {...partner};
    newPartner.phones = !Array.isArray(partner.phones)
      ? partner.phones?.split(',')
      : partner.phones;
    newPartner.emails = !Array.isArray(partner.emails)
      ? partner.emails?.split(',')
      : partner.emails;

    if (location.state) {
      await editPartner(token, location.state.document, newPartner, handleSuccess);
    } else {
      await createPartner(token, newPartner, handleSuccess);
    }
  };

  const canPostForm = () => {
    if (isCreatingPartner || isEditingPartner) return false;
    return !(!partner.document.trim() || !partner.fantasyName.trim() || !partner.legalName.trim());
  };

  return (
    <Template>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={selectedTab} onChange={handleChange}>
          <Tab label="Informações básicas" />
          <Tab label="Endereço" />
        </Tabs>
      </Box>
      {renderContent()}
      <Grid container justifyContent={'flex-end'} sx={{mt: 2}}>
        <Grid item>
          <Button sx={{mr: 2}} onClick={handleCancel}>
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button variant={'contained'} onClick={handleSave} disabled={!canPostForm()}>
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Template>
  );
};
