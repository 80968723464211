import {Template} from 'components/Template';
import {FileSection} from 'pages/FileUploadPage/FileSection';
import {ListFilesTable} from 'pages/FileUploadPage/ListFilesTable';
import {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Grid} from '@mui/material';
import {useUploadFile} from 'hooks/drive/useUploadFile';
import {useLocation, useNavigate} from 'react-router';

export const FileUploadPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const {uploadFile, isUploading} = useUploadFile();

  useEffect(() => {
    const p = {};
    Array.from(files)?.forEach(file => (p[file.name] = 0));
    setProgress(p);
  }, [files]);

  const handleCancel = () => {
    const {document, folder} = location.state;
    navigate(`/drive/${document}`, {
      state: {
        folder,
      },
    });
  };

  const handleUpdateProgress = file => {
    return event => {
      const {loaded, total, lengthComputable} = event;
      if (lengthComputable) {
        const percent = (100 * loaded) / total;
        setProgress(prev => ({...prev, [file.name]: +`${percent.toFixed(2)}`}));
      }
    };
  };

  const handleUploadFiles = async () => {
    const {folder, document} = location.state;
    for (let file of files) {
      await uploadFile(document, folder, file, handleUpdateProgress(file));
    }
    navigate(`/drive/${document}`, {state: {folder}});
  };

  if (!location.state?.document || !location.state?.folder) {
    navigate('/accounts');
    return null;
  }

  return (
    <Template>
      <FileSection onChangeFiles={setFiles} />
      <ListFilesTable files={files} progress={progress} />
      <Grid container justifyContent={'flex-end'} mt={4}>
        <Box sx={{display: 'flex', gap: 2}}>
          <Button onClick={handleCancel}>Cancelar</Button>
          <Button onClick={handleUploadFiles} variant={'contained'} disabled={isUploading}>
            {isUploading ? <CircularProgress color={'info'} size={'20px'} /> : `Enviar arquivos`}
          </Button>
        </Box>
      </Grid>
    </Template>
  );
};
