import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

export const ListAdminUsers = ({adminUsers}) => {
  const renderRows = () => {
    return adminUsers?.map(adminUser => (
      <TableRow key={adminUser.id}>
        <TableCell>{adminUser.email}</TableCell>
        <TableCell>{`${adminUser.name} ${adminUser.lastName}`}</TableCell>
        <TableCell>{new Date(adminUser.lastAccess).toLocaleString()}</TableCell>
        <TableCell>-</TableCell>
        <TableCell></TableCell>
      </TableRow>
    ));
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader>Email</TableCellHeader>
            <TableCellHeader>Nome</TableCellHeader>
            <TableCellHeader>Último acesso</TableCellHeader>
            <TableCellHeader>Status</TableCellHeader>
            <TableCellHeader></TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>{renderRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};

const TableCellHeader = styled(TableCell)`
  font-weight: bold;
`;
