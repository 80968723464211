import {useCallback} from 'react';
import {PATH} from 'constants/paths';
import {useFetch} from 'hooks/useFetch';

export const useGetModulesForAccount = () => {
  const {data, isLoading, sendRequest} = useFetch();

  const getModulesForAccount = useCallback(
    async (token, accountID) => {
      await sendRequest(
        'GET',
        PATH.modulesForAccount(accountID),
        {
          Authorization: token,
        },
        null
      );
    },
    [sendRequest]
  );

  return {
    modulesForAccount: data,
    isLoadingModulesForAccounts: isLoading,
    getModulesForAccount,
  };
};
