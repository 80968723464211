import {useDispatch, useSelector} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router';
import {AccountPage} from 'pages/AccountPage';
import {ManageAccount} from 'pages/AccountPage/ManageAccount';
import {DrivePage} from 'pages/DrivePage';
import {FileUploadPage} from 'pages/FileUploadPage';
import {UsersPage} from 'pages/UsersPage';
import {LoginPage} from 'pages/LoginPage';
import {useEffect} from 'react';
import {CenteredLoading} from 'components/CenteredLoading';
import {useUserInfos} from 'hooks/authentication/useUserInfos';
import {setActiveToken, setUserInfos} from 'store/authentication';
import {ReverseProxyPage} from 'pages/ReverseProxyPage';
import {AdminUsersPage} from 'pages/AdminUsersPage';
import {ManageAdminUser} from 'pages/AdminUsersPage/ManageAdminUser';
import {PartnersPage} from 'pages/PartnersPage';
import {ManagePartner} from 'pages/PartnersPage/ManagePartner';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {darkTheme, lightTheme} from 'constants/themes';
import {DNSPage} from 'pages/DNSPage';

function App() {
  const isDarkMode = useSelector(state => state.ui.isDarkMode);
  const token = useSelector(state => state.authentication.token);
  const userInfos = useSelector(state => state.authentication.userInfos);

  const dispatch = useDispatch();
  const {loadUserInfos} = useUserInfos();

  useEffect(() => {
    if (token) {
      loadUserInfos(
        token,
        resp => dispatch(setUserInfos(resp)),
        () => dispatch(setActiveToken(''))
      ).then();
    }
  }, [token, dispatch, loadUserInfos]);

  if (!token) {
    return <LoginPage />;
  }

  if (token && !userInfos) {
    return (
      <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        <CssBaseline />
        <CenteredLoading message={'Carregando sessão do usuário!'} />;
      </ThemeProvider>
    );
  }

  return (
    <Routes>
      <Route path={'/accounts'} element={<AccountPage />} />
      <Route path={'/accounts/manage'} element={<ManageAccount />} />
      <Route path={'/partners'} element={<PartnersPage />} />
      <Route path={'/partners/manage'} element={<ManagePartner />} />
      <Route path={'/drive/upload'} element={<FileUploadPage />} />
      <Route path={'/drive/:document'} element={<DrivePage />} />
      <Route path={'/users/:document'} element={<UsersPage />} />
      <Route path={'/adminusers'} element={<AdminUsersPage />} />
      <Route path={'/adminusers/manage'} element={<ManageAdminUser />} />
      <Route path={'/dns'} element={<DNSPage />} />
      <Route path={'/reverseproxy'} element={<ReverseProxyPage />} />
      <Route path={'/'} element={<Navigate to={'/accounts'} />} />
    </Routes>
  );
}

export default App;
