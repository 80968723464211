import {Grid, TextField} from '@mui/material';
import {useCEP} from 'hooks/utils/useCEP';
import {useEffect} from 'react';

export const FormAddress = ({value, onChange}) => {
  const {address, getCEP} = useCEP();

  useEffect(() => {
    if (address) {
      const {logradouro: street, bairro: neighborhood, uf: state, localidade: city} = address;
      onChange({
        ...value,
        street,
        neighborhood,
        state,
        city,
      });
    }
  }, [address, onChange, value]);

  const handleChangeValue = async ({target}) => {
    onChange({
      ...value,
      [target.name]: target.value,
    });

    if (target.name === 'zipcode') {
      await getCEP(target.value);
    }
  };

  return (
    <Grid container>
      <Grid container spacing={2} margin={2}>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label={'CEP'}
            value={value.zipcode}
            name="zipcode"
            onChange={handleChangeValue}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label={'Logradouro'}
            value={value.street}
            name={'street'}
            onChange={handleChangeValue}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField
            fullWidth
            label={'Número'}
            value={value.number}
            name={'number'}
            onChange={handleChangeValue}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label={'Complemento'}
            value={value.complement}
            name={'complement'}
            onChange={handleChangeValue}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} margin={2}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label={'Bairro'}
            value={value.neighborhood}
            name={'neighborhood'}
            onChange={handleChangeValue}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label={'Cidade'}
            value={value.city}
            name={'city'}
            onChange={handleChangeValue}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label={'Estado'}
            value={value.state}
            name={'state'}
            onChange={handleChangeValue}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label={'País'}
            value={value.country}
            name={'country'}
            onChange={handleChangeValue}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
