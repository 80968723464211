export const PATH = {
  accounts: '/api/v1/accounts',
  accountsByPartners: '/api/v1/partners/accounts',
  adminusers: '/api/v1/users',
  dns: '/api/v1/dns',
  files: '/api/v1/files',
  login: '/api/v1/login',
  modules: '/api/v1/modules',
  partners: '/api/v1/partners',
  reverseProxyRule: '/api/v1/reverseproxy/rules',
  reverseProxyHosts: '/api/v1/reverseproxy/hosts',
  modulesForAccount: accountID => `/api/v1/accounts/modules?accountID=${accountID}`,
  sessionInfos: token => `/api/v1/session/${token}`,
};
