import {useCallback, useState} from 'react';
import {PATH} from 'constants/paths';

export const useCreateFolder = () => {
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  const sendRequest = useCallback(async (token, document, path, folder) => {
    try {
      setLoading(true);
      const resp = await fetch(`${PATH.files}/create_folder?document=${document}`, {
        method: 'POST',
        body: JSON.stringify({path, folder}),
        headers: {
          authorization: token,
        },
      });
      if (!resp.ok) {
        setError('invalid response');
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    isCreatingFolder: isLoading,
    errorOnCreateFolder: error,
    createFolder: sendRequest,
  };
};
