import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {DateTime} from 'luxon';
import {toHuman} from 'formatters/bytes';
import {MoreOptions} from 'components/MoreOptions/MoreOptions';
import {Description, Folder} from '@mui/icons-material';

export const ListFilesTable = ({files, onNavigate, onDeleteFile}) => {
  const handleNavigate = file => {
    if (getFileType(file.name) !== 'directory') return;
    onNavigate(file.name);
  };

  const handleDeleteFile = file => onDeleteFile(file);

  const options = [{text: 'Excluir', onClick: handleDeleteFile}];

  const renderIconForFile = file => {
    if (getFileType(file.name) === 'directory') return <Folder sx={{mr: 1}} />;
    return <Description sx={{mr: 1}} />;
  };

  const getFileType = filename => {
    const splitted = filename?.split('.');
    if (splitted?.length > 1) return splitted[splitted.length - 1];
    if (filename[filename.length - 1] === '/') return 'directory';
    return '-';
  };

  const renderBody = () => {
    return files?.map(file => {
      return (
        <TableRow
          key={file.name}
          hover
          onClick={() => handleNavigate(file)}
          sx={{cursor: getFileType(file?.name) === 'directory' ? 'pointer' : 'default'}}
        >
          <TableCell>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              {renderIconForFile(file)}
              {file.name}
            </Box>
          </TableCell>
          <TableCell>{toHuman(file.size)}</TableCell>
          <TableCell>{getFileType(file.name)}</TableCell>
          <TableCell>{DateTime.fromISO(file.modTime).toFormat('dd/MM/yyyy HH:mm:ss')}</TableCell>
          <TableCell sx={{maxWidth: '70px'}} align={'right'}>
            <MoreOptions options={options} data={file} />
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader>Nome</TableCellHeader>
            <TableCellHeader>Tamanho</TableCellHeader>
            <TableCellHeader>Tipo</TableCellHeader>
            <TableCellHeader>Data de modificação</TableCellHeader>
            <TableCellHeader sx={{maxWidth: '70px'}} align={'right'}></TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>{renderBody()}</TableBody>
      </Table>
    </TableContainer>
  );
};

const TableCellHeader = styled(TableCell)`
  font-weight: bold;
`;
