import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import {LockOutlined} from '@mui/icons-material';
import {useLogin} from 'hooks/authentication/useLogin';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {setActiveToken} from 'store/authentication';

export const LoginPage = () => {
  const {login, isLogging} = useLogin();
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();

  const handleSuccess = ({token}) => {
    localStorage.setItem('sigCoreAdminToken', token);
    dispatch(setActiveToken(token));
  };
  const handleInvalidLogin = () => setMessage('Usuário ou senha inválidos. Tente novamente!');
  const handleError = () => {
    setMessage('Houve um erro na validação do usuário. Tente novamente mais tarde!');
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    await login(
      data.get('email'),
      data.get('password'),
      handleSuccess,
      handleInvalidLogin,
      handleError
    );
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Fazer Login
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1, maxWidth: '500px'}}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Senha"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Lembrar meu usuário"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{mt: 3, mb: 2}}
          disabled={isLogging}
        >
          Entrar
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              Esqueceu sua senha?
            </Link>
          </Grid>
        </Grid>
        {message && (
          <Grid container sx={{mt: 2}}>
            <Grid item xs>
              <Alert severity="error" onClose={() => setMessage('')}>
                {message}
              </Alert>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};
