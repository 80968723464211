import {useFetch} from 'hooks/useFetch';
import {useCallback} from 'react';
import {PATH} from 'constants/paths';

export const useGetModules = () => {
  const {isLoading, sendRequest, data} = useFetch();

  const getModules = useCallback(
    async token => {
      await sendRequest(
        'GET',
        PATH.modules,
        {
          Authorization: token,
        },
        null
      );
    },
    [sendRequest]
  );

  return {isLoadingModules: isLoading, getModules, modules: data};
};
