import {Template} from 'components/Template';
import {useLocation, useNavigate, useParams} from 'react-router';
import {CenteredLoading} from 'components/CenteredLoading';
import {useListFiles} from 'hooks/drive/useListFiles';
import {ListFilesTable} from 'pages/DrivePage/ListFilesTable';
import {useEffect, useState} from 'react';
import {Box, Breadcrumbs, Button, Grid, Link, Typography} from '@mui/material';
import {MoreOptions} from 'components/MoreOptions/MoreOptions';
import {ModalAskFolderName} from 'components/ModalAskFolderName';
import {useCreateFolder} from 'hooks/drive/useCreateFolder';
import {ModalYesNo} from 'components/ModalYesNo/ModalYesNo';
import {useDeleteFile} from 'hooks/drive/useDeleteFile';
import {EmptyState} from 'components/EmptyState';
import {useSelector} from 'react-redux';

export const DrivePage = () => {
  const {document} = useParams();

  const token = useSelector(state => state.authentication.token);

  const [folder, setFolder] = useState('/');
  const [isCreatingFolder, setCreatingFolder] = useState(false);
  const [isDeleting, setDeleting] = useState([]);

  const {listFiles, files, isLoadingFiles} = useListFiles();
  const {createFolder} = useCreateFolder();
  const {deleteFile, isDeletingFile} = useDeleteFile();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.folder) {
      setFolder(location.state.folder);
    }
  }, [location.state]);

  useEffect(() => {
    if (document) {
      listFiles(token, document, folder).then();
    }
  }, [document, listFiles, folder, token]);

  const handleNavigate = newFolder => setFolder(prev => `${prev}${newFolder}`);

  const handleSendFile = () => {
    navigate('/drive/upload', {
      state: {
        document,
        folder,
      },
    });
  };

  const renderActionForEmptyState = () => {
    return (
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{mt: 1}}>
          <Button variant={'contained'} onClick={handleSendFile}>
            Enviar arquivo
          </Button>
        </Box>
        <Box sx={{mt: 1}}>
          <Button onClick={() => setCreatingFolder(true)}>Criar uma pasta</Button>
        </Box>
      </Box>
    );
  };

  const renderFiles = () => {
    if (isLoadingFiles) return <CenteredLoading />;
    if (!files?.length)
      return <EmptyState message={'Esta pasta está vazia!'} action={renderActionForEmptyState()} />;
    return (
      <ListFilesTable
        files={files}
        onNavigate={handleNavigate}
        onDeleteFile={data => setDeleting([data])}
      />
    );
  };

  const renderBreadcrumbItems = () => {
    const pathSplitted = folder.split('/');
    const links = [
      <Link
        key={'/'}
        fontSize={'14px'}
        underline={'hover'}
        sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
        onClick={() => setFolder('/')}
      >
        Início
      </Link>,
    ];

    pathSplitted.forEach((path, index) => {
      if (path !== '')
        links.push(
          <Link
            key={path}
            align={'center'}
            fontSize={'14px'}
            underline={'hover'}
            sx={{cursor: 'pointer'}}
            onClick={() => setFolder(`${pathSplitted.slice(0, index + 1).join('/')}/`)}
          >
            {path}
          </Link>
        );
    });

    return links;
  };

  const options = [
    {text: 'Criar pasta', onClick: () => setCreatingFolder(true)},
    {text: 'Enviar arquivo', onClick: handleSendFile},
    {divider: true},
    {text: 'Excluir arquivos', onClick: () => undefined, disabled: true},
  ];

  const handleCreateFolder = async ({folderName}) => {
    await createFolder(token, document, folder, folderName);
    await listFiles(token, document, folder);
  };

  const handleDeleteFile = async () => {
    for (let file of isDeleting) {
      await deleteFile(token, document, `${folder}${file.name}`);
    }

    setDeleting([]);
    await listFiles(token, document, folder);
  };

  return (
    <Template>
      <ModalAskFolderName
        open={isCreatingFolder}
        onClose={() => setCreatingFolder(false)}
        onChange={handleCreateFolder}
      />
      <ModalYesNo
        open={isDeleting.length}
        text={'Excluir arquivos selecionados?'}
        noText={'Cancelar'}
        yesText={'Sim, excluir!'}
        title={'Excluir arquivos'}
        onNo={() => setDeleting([])}
        loading={isDeletingFile}
        onYes={handleDeleteFile}
      />
      <Grid container>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <Breadcrumbs
              separator={
                <Typography fontSize={'1.1rem'} color={'rgb(156,156,156)'}>
                  {'>'}
                </Typography>
              }
            >
              {renderBreadcrumbItems()}
            </Breadcrumbs>
          </Grid>
          <Grid item>
            <MoreOptions options={options} />
          </Grid>
        </Grid>
        <Grid container mt={4}>
          <Grid item xs={12}>
            {renderFiles()}
          </Grid>
        </Grid>
      </Grid>
    </Template>
  );
};
