import {useGetModules} from 'hooks/module/useGetModules';
import {CenteredLoading} from 'components/CenteredLoading';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  Typography,
} from '@mui/material';
import {EmptyState} from 'components/EmptyState';
import {ExpandMore} from '@mui/icons-material';

export const FormModules = ({value, onChange, isLoadingModulesForAccounts}) => {
  const token = useSelector(state => state.authentication.token);

  const {getModules, isLoadingModules, modules} = useGetModules();

  useEffect(() => {
    getModules(token).then();
  }, [token, getModules]);

  const handleSelectModule = (event, moduleKey) => {
    event.stopPropagation();
    onChange(moduleKey);
  };

  const renderModules = () => {
    return modules.map(module => (
      <Accordion key={module.key} expanded={false}>
        <AccordionSummary expandIcon={<ExpandMore />} sx={{alignItems: 'center'}}>
          <Checkbox
            sx={{height: '20px', width: '20px', mr: 2}}
            checked={value.some(m => m.module === module.key)}
            onClick={event => handleSelectModule(event, module.key)}
          />
          <Typography>{module.name}</Typography>
        </AccordionSummary>
        <AccordionDetails></AccordionDetails>
      </Accordion>
    ));
  };

  if (isLoadingModules || isLoadingModulesForAccounts) return <CenteredLoading />;
  if (!modules) return <EmptyState message={'Ainda não há módulos cadastrados no sistema'} />;

  return (
    <Grid container p={2}>
      <Grid item xs={12}>
        {renderModules(modules)}
      </Grid>
    </Grid>
  );
};
