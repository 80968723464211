import {useCallback, useState} from 'react';
import {PATH} from 'constants/paths';

export const useGetAdminUsers = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const sendRequest = useCallback(
    async (token, onSuccess = () => undefined, onError = () => undefined) => {
      try {
        setLoading(true);
        const url = `${PATH.adminusers}`;
        const resp = await fetch(url, {
          method: 'GET',
          headers: {
            authorization: token,
          },
        });
        if (!resp.ok) {
          setData([]);
          onError();
          return;
        }
        const json = await resp.json();
        setData(json);
        onSuccess(json);
      } catch (e) {
        setData([]);
        onError();
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    adminUsers: data,
    isLoadingAdminUsers: isLoading,
    getAdminUsers: sendRequest,
  };
};
