import {useCallback, useState} from 'react';

export const useFetch = () => {
  const [data, setData] = useState(undefined);
  const [isLoading, setLoading] = useState(false);

  const sendRequest = useCallback(
    async (method, path, headers, body, onSuccess = () => undefined, onError = () => undefined) => {
      try {
        setData(undefined);
        setLoading(true);
        const resp = await fetch(path, {
          method: method,
          headers,
          body: body ? JSON.stringify(body) : null,
        });
        if (!resp.ok) {
          setData(undefined);
          onError();
          return;
        }
        if (resp.status === 204) {
          onSuccess();
        } else {
          const json = await resp.json();
          setData(json);
          onSuccess(json);
        }
      } catch (e) {
        setData(undefined);
        onError();
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    data,
    isLoading,
    sendRequest,
  };
};
