import {
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {toHuman} from 'formatters/bytes';

export const ListFilesTable = ({files, progress}) => {
  const renderBody = () => {
    return Array.from(files)?.map(file => {
      return (
        <TableRow key={file.name} hover>
          <TableCell>{file.name}</TableCell>
          <TableCell>{toHuman(file.size)}</TableCell>
          <TableCell>{file.type || '-'}</TableCell>
          <TableCell>{progress[file.name]}%</TableCell>
        </TableRow>
      );
    });
  };

  const renderTableContent = () => {
    if (!files.length) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={4}>
              <Grid container sx={{mt: 1, p: 4}}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={'center'}
                  alignItems={'center'}
                  textAlign={'center'}
                >
                  <Typography fontWeight={'bold'} textAlign={'center'}>
                    Sem arquivos ou pastas
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={'center'}
                  alignItems={'center'}
                  textAlign={'center'}
                >
                  Você não escolheu nenhum arquivo ou pasta para carregar.
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return <TableBody>{renderBody()}</TableBody>;
  };

  return (
    <TableContainer component={Paper} sx={{mt: 4}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader>Nome</TableCellHeader>
            <TableCellHeader>Tamanho</TableCellHeader>
            <TableCellHeader>Tipo</TableCellHeader>
            <TableCellHeader>Progresso</TableCellHeader>
          </TableRow>
        </TableHead>
        {renderTableContent()}
      </Table>
    </TableContainer>
  );
};

const TableCellHeader = styled(TableCell)`
  font-weight: bold;
`;
