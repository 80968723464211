import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export const ModalYesNo = ({
                             title,
                             text,
                             yesText = "Sim",
                             noText = "Não",
                             onYes,
                             onNo,
                             open,
                             onYesColor = "primary",
                             loading
                           }) => {
  return (
    <Dialog open={Boolean(open)} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {text}
      </DialogContent>
      <DialogActions>
        <Button onClick={onNo}>{noText}</Button>
        <Button disabled={loading} onClick={onYes} variant={"contained"} color={onYesColor}>
          {loading ? <CircularProgress size={"1.2rem"} /> : yesText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};