import {
  AltRoute,
  ContactSupport,
  DriveFileRenameOutlineOutlined,
  People,
  VerifiedUser,
} from '@mui/icons-material';

export const DRAWER = [
  {
    title: 'Backstage',
    items: [
      {pathname: '/accounts', label: 'Contas', icon: People},
      {pathname: '/partners', label: 'Parceiros', icon: ContactSupport},
      {pathname: '/adminusers', label: 'Usuários', icon: VerifiedUser},
    ],
  },
  {
    title: 'Infraestrutura',
    items: [
      {pathname: '/dns', label: 'DNS', icon: DriveFileRenameOutlineOutlined},
      {pathname: '/reverseproxy', label: 'Proxy Reverso', icon: AltRoute},
    ],
  },
];
