import {createTheme} from '@mui/material';

export const lightTheme = createTheme({
  typography: {
    fontSize: 12,
  },
});
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontSize: 12,
  },
});
