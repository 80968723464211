import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import {useEffect, useState} from 'react';

const initialState = {folderName: ''};

export const ModalAskFolderName = ({open, onClose, value, onChange}) => {
  const [localState, setLocalState] = useState(initialState);

  const handleChangeForm = ({target}) => {
    setLocalState({
      ...localState,
      [target.name]: target.value.replace('/', ''),
    });
  };

  const handleClose = () => {
    setLocalState(initialState);
    onClose();
  };

  const handleYes = () => {
    onChange(localState);
    setLocalState(initialState);
    onClose();
  };

  useEffect(() => {
    if (value) setLocalState(value);
  }, [value]);

  return (
    <Dialog open={Boolean(open)} fullWidth>
      <DialogTitle>Informe o nome da pasta a ser criada</DialogTitle>
      <DialogContent>
        <Grid container gap={2} pt={1}>
          <Grid item xs={12}>
            <TextField
              label={'Nome da pasta'}
              fullWidth
              value={localState.folderName}
              name={'folderName'}
              onChange={handleChangeForm}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container px={2} pb={1} justifyContent={'flex-end'}>
          <Box sx={{display: 'flex', gap: 2}}>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={handleYes}
              variant={'contained'}
              disabled={!localState.folderName.trim()}
            >
              Criar
            </Button>
          </Box>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
