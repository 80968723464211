import { useCallback, useEffect, useState } from "react";

export const useFileChooser = (accept = [], multiple = false) => {
  const [files, setFiles] = useState([]);
  const [inputElement, setInputElement] = useState();

  useEffect(() => {
    const onChooseFile = (event) => {
      const fs = event.target.files;
      setFiles(fs);
    };

    if (!inputElement) {
      const localInputElement = document.createElement("input");
      localInputElement.type = "file";
      localInputElement.accept = accept.join(",");
      localInputElement.multiple = multiple;

      localInputElement.addEventListener("change", onChooseFile);
      setInputElement(localInputElement);
    }
  }, [inputElement, multiple, accept]);

  const pickFile = useCallback(() => {
    if (inputElement) {
      inputElement.click();
    }
  }, [inputElement]);

  const reset = useCallback(() => {
    if (inputElement) {
      inputElement.value = null;
      setFiles([]);
    }
  }, [inputElement]);

  return { pickFile, files, reset };
};