import {Template} from 'components/Template';
import {Grid, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {useGetDNS} from 'hooks/dns/useGetDNS';
import {useEffect} from 'react';
import {CenteredLoading} from 'components/CenteredLoading';
import {EmptyState} from 'components/EmptyState';
import {FormDNS} from 'pages/DNSPage/FormDNS';

export const DNSPage = () => {
  const token = useSelector(state => state.authentication.token);

  const {dnsEntries, loadDNS, isLoadingDNS} = useGetDNS();

  useEffect(() => {
    loadDNS(token).then();
  }, [loadDNS, token]);

  const handleUpdateSuccess = async () => loadDNS(token);

  const renderForm = () => {
    if (isLoadingDNS) return <CenteredLoading />;
    if (!dnsEntries?.length)
      return <EmptyState message={'Ainda não há entradas DNS cadastradas!'} />;
    return <FormDNS value={dnsEntries} onUpdate={handleUpdateSuccess} />;
  };

  return (
    <Template>
      <Grid container spacing={2}>
        <Grid xs={6} item>
          <Typography variant={'h4'}>Servidor DNS</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} margin={1}>
            <Grid item xs={12} md={2} textAlign={'center'}>
              <Typography>Host</Typography>
            </Grid>
            <Grid item xs={12} md={2} textAlign={'center'}>
              <Typography>TTL</Typography>
            </Grid>
            <Grid item xs={12} md={2} textAlign={'center'}>
              <Typography>Tipo</Typography>
            </Grid>
            <Grid item xs={12} md={2} textAlign={'center'}>
              <Typography>Valor</Typography>
            </Grid>
            <Grid item xs={12} md={2} textAlign={'center'}>
              <Typography>Hits</Typography>
            </Grid>
            <Grid item xs={12} md={2} textAlign={'center'}>
              <Typography>Último uso</Typography>
            </Grid>
          </Grid>
          {renderForm()}
        </Grid>
      </Grid>
    </Template>
  );
};
