import {
  Alert,
  AppBar,
  Avatar,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Snackbar,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import {ChevronLeft, DarkMode, LightMode, Menu} from '@mui/icons-material';
import {useDispatch, useSelector} from 'react-redux';
import {toggleDarkMode, toggleGlobalMenu} from 'store/ui';
import {useNavigate} from 'react-router';
import {DRAWER} from './drawer';
import {setActiveNotification} from 'store/notification';
import {useState} from 'react';
import {UserMenu} from 'components/Template/UserMenu';
import {darkTheme, lightTheme} from 'constants/themes';

export const Template = ({padding, children, maxWidth}) => {
  const globalMenuOpen = useSelector(state => state.ui.globalMenuOpen);
  const notification = useSelector(state => state.notification.active);
  const isDarkMode = useSelector(state => state.ui.isDarkMode);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [avatarEl, setAvatarEl] = useState();
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  const handleToggleDarkMode = () => dispatch(toggleDarkMode());
  const handleToggleGlobalMenu = () => dispatch(toggleGlobalMenu());
  const handleCloseUserMenu = () => setUserMenuOpen(false);
  const handleOpenUserMenu = () => setUserMenuOpen(true);

  const handleNavigate = drawerItem => {
    navigate(drawerItem.pathname);
    handleToggleGlobalMenu();
  };

  const handleCloseNotification = () => {
    dispatch(setActiveNotification({...notification, open: false}));
  };

  const renderDrawerSections = ({title, items}) => {
    return (
      <List key={title} subheader={<ListSubheader component="div">{title}</ListSubheader>}>
        {renderItem(items)}
      </List>
    );
  };

  const renderItem = items => {
    return items.map(drawerItem => {
      return (
        <ListItemButton onClick={handleNavigate.bind(null, drawerItem)} key={drawerItem.label}>
          <ListItemIcon>
            <drawerItem.icon />
          </ListItemIcon>
          <ListItemText primary={drawerItem.label} />
        </ListItemButton>
      );
    });
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <AppBar>
        <Toolbar variant={'dense'}>
          <IconButton edge="start" color="inherit" onClick={handleToggleGlobalMenu}>
            <Menu />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{flexGrow: 1, ml: '30px'}}
          >
            SigCore Admin
          </Typography>
          <IconButton
            onClick={handleToggleDarkMode}
            color="inherit"
            sx={{mr: 2}}
            title={isDarkMode ? 'Acender as luzes' : 'Apagar as luzes'}
          >
            {isDarkMode ? <LightMode /> : <DarkMode />}
          </IconButton>
          <IconButton color="inherit" ref={setAvatarEl} onClick={handleOpenUserMenu}>
            <Avatar />
          </IconButton>
          <UserMenu anchorEl={avatarEl} open={userMenuOpen} onClose={handleCloseUserMenu} />
        </Toolbar>
      </AppBar>
      <Drawer open={globalMenuOpen} onClose={handleToggleGlobalMenu}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
            minWidth: '300px',
          }}
        >
          <IconButton onClick={handleToggleGlobalMenu}>
            <ChevronLeft />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">{DRAWER.map(renderDrawerSections)}</List>
      </Drawer>
      <Container
        sx={{mt: 7, p: isNaN(padding) ? 4 : padding}}
        maxWidth={maxWidth || 'lg'}
        disableGutters
      >
        {children}
      </Container>
      <Snackbar
        open={notification.open}
        autoHideDuration={notification.autoHideDuration}
        onClose={handleCloseNotification}
        TransitionComponent={Slide}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{width: '100%'}}
          elevation={1}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};
