import {createSlice} from '@reduxjs/toolkit';

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    token: localStorage.getItem('sigCoreAdminToken') || '',
    userInfos: undefined,
    config: undefined,
  },
  reducers: {
    setActiveToken: (state, action) => {
      state.token = action.payload;
    },
    logout: (state, action) => {
      state.userInfos = null;
      state.token = null;
      localStorage.removeItem('sigCoreAdminToken');
    },
    setUserInfos: (state, action) => {
      state.userInfos = action.payload;
    },
  },
});

export const {setActiveToken, setUserInfos, logout} = authenticationSlice.actions;

export default authenticationSlice.reducer;
