import {Template} from 'components/Template';
import {useParams} from 'react-router';
import {CenteredLoading} from 'components/CenteredLoading';
import {useEffect} from 'react';
import {Box, Button, Grid, Typography} from '@mui/material';
import {MoreOptions} from 'components/MoreOptions/MoreOptions';
import {EmptyState} from 'components/EmptyState';
import {useGetUsers} from 'hooks/users/useGetUsers';

export const UsersPage = () => {
  const {document} = useParams();
  const {getUsers, users, isLoadingUsers} = useGetUsers();

  useEffect(() => {
    if (document) {
      getUsers(document).then();
    }
  }, [document, getUsers]);

  const renderActionForEmptyState = () => {
    return (
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{mt: 1}}>
          <Button variant={'contained'} onClick={() => undefined}>
            Criar usuário
          </Button>
        </Box>
      </Box>
    );
  };

  const renderUsers = () => {
    if (isLoadingUsers) return <CenteredLoading />;
    if (!users?.length)
      return (
        <EmptyState
          message={'Essa conta ainda não possui usuários!'}
          action={renderActionForEmptyState()}
        />
      );
    return <div>Lista de usuários</div>;
  };

  const options = [{text: 'Criar usuário', onClick: () => undefined}];

  return (
    <Template>
      <Grid container>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid xs={6} item>
            <Typography variant={'h4'}>Usuários</Typography>
          </Grid>
          <Grid item>
            <MoreOptions options={options} />
          </Grid>
        </Grid>
        <Grid container mt={4}>
          <Grid item xs={12}>
            {renderUsers()}
          </Grid>
        </Grid>
      </Grid>
    </Template>
  );
};
