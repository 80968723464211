import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {MoreOptions} from 'components/MoreOptions/MoreOptions';
import {useNavigate} from 'react-router';

export const ListPartners = ({partners, onDelete}) => {
  const navigate = useNavigate();

  const options = [
    {
      text: 'Editar',
      onClick: partner => navigate('/partners/manage', {state: partner}),
    },
    {
      text: 'Excluir',
      onClick: onDelete,
    },
  ];

  const renderAddress = account =>
    `${account.street}, ${account.number} - ${account.neighborhood} -${account.city}/${account.state}`;

  const renderRows = () => {
    return partners?.map(partner => (
      <TableRow key={partner.document}>
        <TableCell>{partner.document}</TableCell>
        <TableCell>{partner.fantasyName}</TableCell>
        <TableCell>{renderAddress(partner)}</TableCell>
        <TableCell>
          <MoreOptions options={options} data={partner} />
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader>Documento</TableCellHeader>
            <TableCellHeader>Nome</TableCellHeader>
            <TableCellHeader>Endereço</TableCellHeader>
            <TableCellHeader></TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>{renderRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};

const TableCellHeader = styled(TableCell)`
  font-weight: bold;
`;
