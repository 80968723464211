import {useCallback, useState} from 'react';
import {PATH} from 'constants/paths';

export const useCreatePartner = () => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  const sendRequest = useCallback(
    async (token, body, onSuccess = () => undefined, onError = () => undefined) => {
      try {
        setLoading(true);
        const resp = await fetch(PATH.partners, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            authorization: token,
          },
        });
        if (!resp.ok) {
          setData(undefined);
          setError('invalid response');
          onError();
          return;
        }
        const json = await resp.json();
        setData(json);
        onSuccess(json);
      } catch (e) {
        setData(undefined);
        setError(e);
        onError();
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    partner: data,
    isCreatingPartner: isLoading,
    errorOnCreatePartner: error,
    createPartner: sendRequest,
  };
};
