import {Template} from 'components/Template';
import {useGetAccounts} from 'hooks/accounts/useGetAccounts';
import {ListAccounts} from './ListAccounts';
import {Button, Grid, Typography} from '@mui/material';
import {Add} from '@mui/icons-material';
import {useNavigate} from 'react-router';
import {CenteredLoading} from 'components/CenteredLoading';
import {EmptyState} from 'components/EmptyState';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';

export const AccountPage = () => {
  const token = useSelector(state => state.authentication.token);
  const {accounts, isLoadingAccounts, loadAccounts} = useGetAccounts();
  const navigate = useNavigate();

  useEffect(() => {
    loadAccounts(token).then();
  }, [loadAccounts, token]);

  const renderAccountsList = () => {
    if (isLoadingAccounts) return <CenteredLoading />;
    if (!accounts?.length) return <EmptyState message={'Ainda não há contas cadastradas!'} />;
    return <ListAccounts accounts={accounts} />;
  };

  return (
    <Template>
      <Grid container spacing={2}>
        <Grid xs={6} item>
          <Typography variant={'h4'}>Contas</Typography>
        </Grid>
        <Grid xs={6} item container justifyContent={'flex-end'}>
          <Button startIcon={<Add />} onClick={() => navigate('/accounts/manage')}>
            Criar nova
          </Button>
        </Grid>
        <Grid item xs={12} sx={{minHeight: '200px'}}>
          {renderAccountsList()}
        </Grid>
      </Grid>
    </Template>
  );
};
