import {useCallback, useState} from 'react';
import {PATH} from 'constants/paths';

export const useDeleteFile = () => {
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  const sendRequest = useCallback(async (token, document, path) => {
    try {
      setLoading(true);
      const resp = await fetch(`${PATH.files}?document=${document}&path=${path}`, {
        method: 'DELETE',
        headers: {
          authorization: token,
        },
      });
      if (!resp.ok) {
        setError('invalid response');
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    isDeletingFile: isLoading,
    errorOnDeleteFile: error,
    deleteFile: sendRequest,
  };
};
