import {FormControl, Grid, MenuItem, Select, TextField, Typography} from '@mui/material';

export const FormDNS = ({value}) => {
  const renderLine = entry => (
    <Grid container spacing={2} margin={1}>
      <Grid item xs={12} md={2}>
        <TextField fullWidth value={entry.host} />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField fullWidth value={entry.ttl} />
      </Grid>
      <Grid item xs={12} md={2}>
        <FormControl fullWidth>
          <Select fullWidth value={entry.type}>
            <MenuItem value={'A'}>A</MenuItem>
            <MenuItem value={'CNAME'}>CNAME</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField fullWidth value={entry.value} />
      </Grid>
      <Grid container item xs={12} md={2} justifyContent={'center'} alignItems={'center'}>
        <Typography>{entry.hits}</Typography>
      </Grid>
      <Grid container item xs={12} md={2} justifyContent={'center'} alignItems={'center'}>
        <Typography>{new Date(entry.lastHit).toLocaleString()}</Typography>
      </Grid>
    </Grid>
  );

  return value.map(renderLine);
};
