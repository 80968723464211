import {createSlice} from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    globalMenuOpen: false,
    showingInfosForFeature: '',
    isDarkMode: localStorage.getItem('sigCoreDarkMode') === 'true' || false,
  },
  reducers: {
    toggleGlobalMenu: state => {
      state.globalMenuOpen = !state.globalMenuOpen;
    },
    toggleShowInfosForFeature: (state, {payload}) => {
      if (Array.isArray(payload) && payload.length) {
        state.showingInfosForFeature = payload[0];
      } else {
        state.showingInfosForFeature = '';
      }
    },
    toggleDarkMode: state => {
      localStorage.setItem('sigCoreDarkMode', `${!state.isDarkMode}`);
      state.isDarkMode = !state.isDarkMode;
    },
  },
});

export const {toggleGlobalMenu, toggleShowInfosForFeature, toggleDarkMode} = uiSlice.actions;

export default uiSlice.reducer;
