import {useCallback, useState} from 'react';
import {PATH} from 'constants/paths';

export const useLogin = () => {
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  const sendRequest = useCallback(async (email, password, onSuccess, onInvalidLogin, onError) => {
    try {
      setLoading(true);
      const url = `${PATH.login}`;
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          email,
          password,
        }),
      });
      if (!resp.ok) {
        setData({});
        setError('invalid response');
        if (resp.status === 401) {
          onInvalidLogin();
        } else {
          onError();
        }
        return;
      }
      const json = await resp.json();
      setData(json);
      setError(undefined);
      onSuccess(json);
    } catch (e) {
      setData({});
      setError(e);
      onError();
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loginResp: data,
    isLogging: isLoading,
    errorOnLogin: error,
    login: sendRequest,
  };
};
