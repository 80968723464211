import {PATH} from 'constants/paths';
import {useFetch} from 'hooks/useFetch';
import {useCallback} from 'react';

export const useDeleteReverseProxyRule = () => {
  const {isLoading, sendRequest} = useFetch();

  const deleteRule = useCallback(
    async (token, id, onSuccess = undefined, onError = undefined) => {
      await sendRequest(
        'DELETE',
        `${PATH.reverseProxyRule}/${id}`,
        {
          authorization: token,
        },
        null,
        onSuccess,
        onError
      );
    },
    [sendRequest]
  );

  return {
    isDeletingRule: isLoading,
    deleteRule,
  };
};
