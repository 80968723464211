import {Grid, TextField} from '@mui/material';

export const FormBasicInfos = ({value, onChange, isEditing}) => {
  const handleChangeValue = ({target}) =>
    onChange({
      ...value,
      [target.name]: target.value,
    });

  return (
    <Grid container>
      <Grid container spacing={2} margin={2}>
        <Grid item xs={12} md={3}>
          <TextField
            disabled={isEditing}
            fullWidth
            label={'Documento'}
            value={value.document}
            name={'document'}
            onChange={handleChangeValue}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} margin={2}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={'Nome fantasia'}
            value={value.fantasyName}
            name="fantasyName"
            onChange={handleChangeValue}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={'Razão social'}
            value={value.legalName}
            name={'legalName'}
            onChange={handleChangeValue}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} margin={2}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={'Telefones'}
            value={value.phones}
            name={'phones'}
            onChange={handleChangeValue}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={'Emails'}
            value={value.emails}
            name={'emails'}
            onChange={handleChangeValue}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
