import {
  Paper,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {MoreOptions} from 'components/MoreOptions/MoreOptions';
import {useNavigate} from 'react-router';
import {useGetPartners} from 'hooks/partners/useGetPartners';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';

export const ListAccounts = ({accounts}) => {
  const {partners, loadPartners, isLoadingPartners} = useGetPartners();
  const token = useSelector(state => state.authentication.token);
  const navigate = useNavigate();

  useEffect(() => {
    loadPartners(token).then();
  }, [loadPartners, token]);

  const options = [
    {
      text: 'Editar',
      onClick: account => navigate('/accounts/manage', {state: account}),
    },
    {
      divider: true,
    },
    {
      text: 'Visualizar arquivos',
      onClick: account => navigate(`/drive/${account.id}`),
    },
    {
      text: 'Visualizar usuários',
      onClick: account => navigate(`/users/${account.id}`),
    },
  ];

  const renderAddress = account =>
    `${account.street}, ${account.number} - ${account.neighborhood} -${account.city}/${account.state}`;

  const findPartner = document => partners?.find(partner => document === partner.document);

  const renderPartnerName = account => {
    if (isLoadingPartners) return <Skeleton variant="rect" />;
    return findPartner(account.partner)?.fantasyName || `-`;
  };

  const renderRows = () => {
    return accounts?.map(account => (
      <TableRow key={account.id}>
        <TableCell>{account.id}</TableCell>
        <TableCell>{account.name}</TableCell>
        <TableCell>{renderAddress(account)}</TableCell>
        <TableCell>{account.anniversaryDay}</TableCell>
        <TableCell>{renderPartnerName(account)}</TableCell>
        <TableCell>
          <MoreOptions options={options} data={account} />
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader>Documento</TableCellHeader>
            <TableCellHeader>Nome</TableCellHeader>
            <TableCellHeader>Endereço</TableCellHeader>
            <TableCellHeader>Dia do vencimento da conta</TableCellHeader>
            <TableCellHeader>Parceiro</TableCellHeader>
            <TableCellHeader></TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>{renderRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};

const TableCellHeader = styled(TableCell)`
  font-weight: bold;
`;
