import {
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useGetPartners} from 'hooks/partners/useGetPartners';
import {useGetAccountsByPartners} from 'hooks/partners/useGetAccountsByPartners';

export const PermissionForm = ({value, onChange}) => {
  const token = useSelector(state => state.authentication.token);

  const [allPartners, setAllPartners] = useState(value.allPartners || false);
  const [allAccounts, setAllAccounts] = useState(value.allAccounts || false);
  const [selectedPartners, setSelectedPartners] = useState(value.selectedPartners || []);
  const [selectedAccounts, setSelectedAccounts] = useState(value.selectedAccounts || []);

  const {loadPartners, partners} = useGetPartners();
  const {accounts, loadAccountsByPartners} = useGetAccountsByPartners();

  useEffect(() => {
    onChange({
      allPartners,
      allAccounts,
      selectedAccounts,
      selectedPartners,
    });
  }, [allPartners, allAccounts, selectedAccounts, selectedPartners, onChange]);

  useEffect(() => {
    loadPartners(token).then();
  }, [token, loadPartners]);

  useEffect(() => {
    loadAccountsByPartners(token, allPartners, selectedPartners).then();
  }, [loadAccountsByPartners, token, allPartners, selectedPartners]);

  const isPartnerSelected = partnerDocument =>
    selectedPartners.some(doc => doc === partnerDocument);
  const handleTogglePartner = partnerDocument => {
    if (isPartnerSelected(partnerDocument)) {
      setSelectedPartners(selectedPartners.filter(doc => doc !== partnerDocument));
    } else {
      setSelectedPartners([...selectedPartners, partnerDocument]);
    }
  };

  const isAccountSelected = accountDocument =>
    selectedAccounts.some(doc => doc === accountDocument);
  const handleToggleAccount = accountDocument => {
    if (isAccountSelected(accountDocument)) {
      setSelectedAccounts(selectedAccounts.filter(doc => doc !== accountDocument));
    } else {
      setSelectedAccounts([...selectedAccounts, accountDocument]);
    }
  };

  return (
    <Grid container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <List
            sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
            subheader={<ListSubheader component="div">Parceiros</ListSubheader>}
          >
            <ListItem disablePadding>
              <ListItemButton onClick={() => setAllPartners(prev => !prev)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{'aria-labelledby': 'allPartners'}}
                    checked={allPartners}
                  />
                </ListItemIcon>
                <ListItemText primary={'Usuário tem acesso a todos os parceiros'} />
              </ListItemButton>
            </ListItem>

            {partners?.map(partner => {
              const labelId = `checkbox-list-label-${partner.document}`;

              return (
                <ListItem key={partner.document} disablePadding>
                  <ListItemButton
                    onClick={() => handleTogglePartner(partner.document)}
                    dense
                    disabled={allPartners}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={isPartnerSelected(partner.document)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-labelledby': labelId}}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={partner.fantasyName} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <List
            sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
            subheader={<ListSubheader component="div">Contas</ListSubheader>}
          >
            <ListItem disablePadding>
              <ListItemButton onClick={() => setAllAccounts(prev => !prev)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{'aria-labelledby': 'allAccounts'}}
                    checked={allAccounts}
                  />
                </ListItemIcon>
                <ListItemText primary={'Usuário tem acesso a todas as contas'} />
              </ListItemButton>
            </ListItem>

            {accounts?.map(account => {
              const labelId = `checkbox-list-label-${account.id}`;

              return (
                <ListItem key={account.id} disablePadding>
                  <ListItemButton
                    onClick={() => handleToggleAccount(account.id)}
                    dense
                    disabled={allAccounts}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={isAccountSelected(account.id)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-labelledby': labelId}}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={account.name} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};
