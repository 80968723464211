import {FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, TextField} from '@mui/material';
import {useGetPartners} from 'hooks/partners/useGetPartners';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';

export const FormBasicInfos = ({value, onChange, isEditing}) => {
  const token = useSelector(state => state.authentication.token);
  const {loadPartners, partners, isLoadingPartners} = useGetPartners();

  useEffect(() => {
    loadPartners(token).then();
  }, [loadPartners, token]);

  const handleChangeValue = ({target}) =>
    onChange({
      ...value,
      [target.name]: target.value,
    });

  const renderPartnersSelector = () => {
    if (isLoadingPartners) return <Skeleton variant={'rect'} height={53} />;
    return (
      <FormControl fullWidth>
        <InputLabel>Parceiro</InputLabel>
        <Select
          label={'Parceiro'}
          onChange={handleChangeValue}
          value={value.partner}
          name={'partner'}
        >
          {partners.map(partner => (
            <MenuItem key={partner.document} value={partner.document}>
              {partner.fantasyName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Grid container>
      <Grid container spacing={2} margin={2}>
        <Grid item xs={12} md={3}>
          <TextField
            disabled={isEditing}
            fullWidth
            label={'Documento'}
            value={value.document}
            name={'document'}
            onChange={handleChangeValue}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={'Nome'}
            value={value.name}
            name="name"
            onChange={handleChangeValue}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} margin={2}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={'Telefones'}
            value={value.phones}
            name={'phones'}
            onChange={handleChangeValue}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label={'Emails'}
            value={value.emails}
            name={'emails'}
            onChange={handleChangeValue}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} margin={2}>
        <Grid item xs={12} md={4}>
          {renderPartnersSelector()}
        </Grid>
      </Grid>
    </Grid>
  );
};
