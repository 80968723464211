export const initialAccount = {
  id: '',
  document: '',
  name: '',
  phones: [],
  emails: [],
  ips: [],
  hosts: [],
  zipcode: '',
  street: '',
  number: '',
  complement: '',
  neighborhood: '',
  city: '',
  state: '',
  country: '',
  partner: '',
};

export const initialPartner = {
  document: '',
  fantasyName: '',
  legalName: '',
  phones: [],
  emails: [],
  zipcode: '',
  street: '',
  number: '',
  complement: '',
  neighborhood: '',
  city: '',
  state: '',
};

export const initialRuleProxyReverse = {
  host: '',
  route: '',
  action: '',
  destination: '',
};

export const initialAdminUser = {
  email: '',
  name: '',
  lastName: '',
  password: '',
  confirmPassword: '',
  needResetPasswordOnNextLogin: true,
  permissions: {
    allPartners: false,
    allAccounts: false,
    selectedAccounts: [],
    selectedPartners: [],
  },
};
