import {configureStore} from '@reduxjs/toolkit';
import uiReducer from './ui';
import notificationReducer from './notification';
import authenticationReducer from './authentication';

export default configureStore({
  reducer: {
    ui: uiReducer,
    notification: notificationReducer,
    authentication: authenticationReducer,
  },
});
