import {useState} from 'react';
import {AccordionItem} from 'pages/ReverseProxyPage/AccordionItem';

export const ListHosts = ({hosts}) => {
  const [accordionOpened, setAccordionOpened] = useState('');

  if (!hosts) return 'No data found!';

  const handleOpenHost = host => {
    if (accordionOpened === host.host) {
      setAccordionOpened('');
    } else {
      setAccordionOpened(host.host);
    }
  };

  return hosts?.map(host => (
    <AccordionItem
      open={accordionOpened === host.host}
      host={host}
      onChangeStatus={handleOpenHost}
      key={host.host}
    />
  ));
};
