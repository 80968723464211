import {useCallback} from 'react';
import {PATH} from 'constants/paths';
import {useFetch} from 'hooks/useFetch';

export const useGetReverseProxyRules = () => {
  const {data, isLoading, sendRequest} = useFetch();

  const request = useCallback(
    async (token, host, onSuccess = undefined, onError = undefined) => {
      if (!host) return;
      await sendRequest(
        'GET',
        `${PATH.reverseProxyRule}?host=${host}`,
        {
          authorization: token,
        },
        null,
        onSuccess,
        onError
      );
    },
    [sendRequest]
  );

  return {
    rules: data,
    isLoadingRules: isLoading,
    getRules: request,
  };
};
