import {useCallback} from 'react';
import {PATH} from 'constants/paths';
import {useFetch} from 'hooks/useFetch';

export const usePutModulesForAccount = () => {
  const {isLoading, sendRequest} = useFetch();

  const putModulesForAccount = useCallback(
    async (token, accountID, modules, onSuccess) => {
      await sendRequest(
        'PUT',
        PATH.modulesForAccount(accountID),
        {
          Authorization: token,
        },
        modules,
        onSuccess
      );
    },
    [sendRequest]
  );

  return {
    isPuttingModulesForAccount: isLoading,
    putModulesForAccount,
  };
};
