import {Box, Typography} from '@mui/material';
import {useFileChooser} from 'hooks/useFileChooser';
import {useEffect, useState} from 'react';

export const FileSection = ({onChangeFiles}) => {
  const {files, pickFile} = useFileChooser([], true);
  const [isDraggingIn, setIsDraggingIn] = useState(false);

  useEffect(() => {
    onChangeFiles(files);
  }, [files, onChangeFiles]);

  const handleDrop = event => {
    event.stopPropagation();
    event.preventDefault();
    let files = event.dataTransfer.files;
    onChangeFiles(files);
    setIsDraggingIn(false);

    return false;
  };

  const handleDragOver = event => {
    event.stopPropagation();
    event.preventDefault();
    return false;
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100px',
        border: '1px dashed silver',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        textAlign: 'center',
      }}
      onClick={() => pickFile()}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnter={() => setIsDraggingIn(true)}
      onDragLeave={() => setIsDraggingIn(false)}
    >
      <Typography color={'gray'}>
        {isDraggingIn
          ? 'Solte os arquivos que você deseja carregar'
          : 'Clique ou arraste os arquivos que você deseja carregar'}
      </Typography>
    </Box>
  );
};
