import {useCallback, useState} from 'react';
import {PATH} from 'constants/paths';

export const useUserInfos = () => {
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  const sendRequest = useCallback(async (token, onSuccess, onError) => {
    try {
      setLoading(true);
      const url = PATH.sessionInfos(token);
      const resp = await fetch(url, {
        method: 'GET',
      });
      if (!resp.ok) {
        setData({});
        setError('invalid response');
        onError();
        return;
      }
      const json = await resp.json();
      setData(json);
      setError(undefined);
      onSuccess(json);
    } catch (e) {
      setData({});
      setError(e);
      onError();
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    userInfos: data,
    isLoadingUserInfos: isLoading,
    errorOnLoadUserInfos: error,
    loadUserInfos: sendRequest,
  };
};
