import {Template} from 'components/Template';
import {Box, Button, Grid, Tab, Tabs} from '@mui/material';
import {useCallback, useState} from 'react';
import {BasicInfosForm} from './BasicInfosForm';
import {initialAdminUser} from 'constants/initialState';
import {PermissionForm} from 'pages/AdminUsersPage/ManageAdminUser/PermissionForm';
import {useNavigate} from 'react-router';
import {useCreateAdminUser} from 'hooks/adminusers/useCreateAdminUser';
import {useSelector} from 'react-redux';

export const ManageAdminUser = () => {
  const navigate = useNavigate();
  const token = useSelector(state => state.authentication.token);

  const [adminUser, setAdminUser] = useState(initialAdminUser);
  const [selectedTab, setSelectedTab] = useState(0);

  const {createAdminUser, isCreatingAdminUser} = useCreateAdminUser();

  const handleChangeTab = (event, newValue) => setSelectedTab(newValue);
  const handleChangeForm = value => setAdminUser(prev => ({...prev, ...value}));
  const handleChangePermissions = useCallback(
    permissions => setAdminUser(prev => ({...prev, permissions})),
    []
  );

  const handleCancel = () => navigate('/adminusers');
  const handleSave = async () => await createAdminUser(token, adminUser, handleCancel);

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return <BasicInfosForm value={adminUser} onChange={handleChangeForm} />;
      case 1:
        return <PermissionForm value={adminUser.permissions} onChange={handleChangePermissions} />;
      default:
        return;
    }
  };

  const isFormValid = () => {
    if (!adminUser.email.trim() || !adminUser.name.trim()) return false;
    if (!adminUser.password.trim() || !adminUser.confirmPassword.trim()) return false;
    if (adminUser.password !== adminUser.confirmPassword) return false;
    return !isCreatingAdminUser;
  };

  return (
    <Template>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={selectedTab} onChange={handleChangeTab}>
          <Tab label="Informações básicas" />
          <Tab label="Permissões" />
        </Tabs>
      </Box>
      <Grid container justifyContent={'flex-end'} sx={{mt: 2}}>
        {renderContent()}
      </Grid>
      <Grid container justifyContent={'flex-end'} sx={{mt: 2}}>
        <Grid item>
          <Button sx={{mr: 2}} onClick={handleCancel}>
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button variant={'contained'} onClick={handleSave} disabled={!isFormValid()}>
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Template>
  );
};
